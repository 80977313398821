const BASIC_URL = "https://back-end.shein.mr-appss.com/public/index.php/api";
// const BASIC_URL = "http://192.168.0.201:8000/api";
const URL_Image = "https://back-end.shein.mr-appss.com/public";
// const URL_Image = "http://192.168.0.201:8000";

const Config = {
  api: {
    Admin: {
      Login: `${BASIC_URL}/v1/login`,
      logout: `${BASIC_URL}/v1/logout`,
      Password_change: `${BASIC_URL}/v1/password/change`,
      profile_update: `${BASIC_URL}/v1/profile/update`,
      v1_profile: `${BASIC_URL}/v1/profile`,
      profile: `${BASIC_URL}/v1/profile`,
      Image: `${URL_Image}`,
    },
    Users: {
      cms_admins: `${BASIC_URL}/v1/cms/admins`,
      v1_cms_users: `${BASIC_URL}/v1/cms/users`,
      v1_cms_users_block: `${BASIC_URL}/v1/cms/users/block`,
    },
    Coupon: {
      v1_cms_coupon: `${BASIC_URL}/v1/cms/coupon`,
    },
    payment_method: {
      v1_cms_payment_method: `${BASIC_URL}/v1/cms/payment/method`,
    },
    Order: {
      v1_cms_order: `${BASIC_URL}/v1/cms/order`,
    },
  },
};
export const API = Config.api;
