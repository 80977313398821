import Swal from "sweetalert2";
const Toast = Swal.mixin({
  toast: true,
  position: "top-right",
  showConfirmButton: false,
  timer: 2500,
  background: "var(--Tre-color)",
  color: "var(--Sec-color)",
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
// const SwalMixin = {
//   methods: {
//     showSuccess(title, text) {
//       Swal.fire({
//         title: title,
//         text: text,
//         icon: "success",
//         confirmButtonText: "Cool",
//         timer: 3000,
//       });
//     },
//     showError(title, text) {
//       Swal.fire({
//         title: title,
//         text: text,
//         icon: "error",
//         confirmButtonText: "Cool",
//         timer: 3000,
//       });
//     },
//     showWarning(title, text) {
//       Swal.fire({
//         title: title,
//         text: text,
//         icon: "warning",
//         confirmButtonText: "Cool",
//         timer: 3000,
//       });
//     },
//     showInfo(title, text) {
//       Swal.fire({
//         title: title,
//         text: text,
//         icon: "info",
//         confirmButtonText: "Cool",
//         timer: 3000,
//       });
//     },
//   },
// };
export default Toast;
